<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>

        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/slogan/slogan.png"
              alt=""
            />
          </div>
        </div>

        <div class="describe" style="margin-bottom: 40px">
          <div class="bg">
            <span>
              2020年，受疫情影响，中华民族经历了巨大考验，企业也不能例外......
              有为信通自年初复工以来，兢兢业业做好疫情防控工作，孜孜以求把优质的产品技术服务带给客户。同时以优秀的成绩给2020画上了一个完美的句号。
              总有这么一群人，他们执着追梦，不负韶华。在岗位上勤勤恳恳，用行动证明自己的能力，用态度感染身边的每一个人。正是有这些优秀的同仁，才让我们在疫情影响下依旧能交出这样的答卷：
              在2020年疫情的影响下公司实现了营业收入增长40%。在2020年疫情的影响下硬件产品销售数字相较于2019年实现了翻倍，公司自研的基于大数据的平台软件产品销售收入也同比增涨。
              在2020年疫情的影响下
              在业务拓展中，2020年，公司借助自身的技术和服务优势，深入能源、教育、政府、军工及部队行业，建立了稳固的基础。其他行业也稳扎稳打，建立了良好的合作关系。
              在2020年疫情的影响下各地子公司增加了华为智慧屏产品线，北京公司增加了机器视觉产品线。
              这傲娇的成绩，源于全体同事的共同努力！2021，扬帆，新的起点，我们将再次起航！
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #9c9b9b;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #fef5f5;
        color: #fe7567;
        font-size: 18px;
        padding: 20px 48px 20px 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0 !important;
        }
        // img:nth-child(3) {
        //   margin: 0;
        // }
      }
    }
    .bg-title {
      height: 28px;
      margin: 50px 0 50px 0;
      img {
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>